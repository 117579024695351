import { Component, OnInit } from '@angular/core';
import { ProfilesService } from '@services';
import { Observable } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.scss']
})
export class ProfilesComponent implements OnInit {

  constructor(private profilesService: ProfilesService) { }

  profiles:Observable<any>;

  ngOnInit() {
    $('[data-toggle="tooltip"]').tooltip();

    this.search();
  }

  search(){
    this.profiles = this.profilesService.search();
  }

  toggleChangeLanguage(){
    $("#changeLanguageModal").modal('show');
  }

  toggleMenu() {
    $(".wrapper").removeClass('open-right-sidebar open-setting-panel').toggleClass('slide-nav-toggle');
  }

  toggleRightSidebar() {
    $(".wrapper").toggleClass('open-right-sidebar').removeClass('open-setting-panel');
  }

  toggleMobileNav() {
    $(".wrapper").toggleClass('mobile-nav-open').removeClass('open-right-sidebar');
  }

}
