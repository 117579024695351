import { BrowserModule } from '@angular/platform-browser';
import { NgModule, COMPILER_OPTIONS, CompilerFactory, Compiler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';  

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// local & session storage
import { StorageServiceModule } from 'angular-webstorage-service';

// import ngx-translate and the http loader
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// end ngx-translate and http loader

// Style
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// google chart
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
// gauge chart
import { NgxGaugeModule } from 'ngx-gauge';

// Angular calendar
import { CalendarModule } from 'angular-calendar';

// Modules
import { LoginComponent, CalendarComponent, ReportsComponent, CustomerComponent, TasksComponent, 
  CustomerdtComponent, CustomerDetailsComponent, TaskdetailsComponent, UpdatetaskDialogComponent, 
  NewnotetaskDialogComponent, ForgotPasswordComponent, ChangePasswordComponent } from '@modules';
import { TasksdtComponent } from '@app/modules/tasks/tasksdt/tasksdt.component';


// shared
import { DatatableComponent, HttpLoaderFactory} from '@shared';

//Services
import { HttpInterceptorService, RouterService, ModuleService, InMemoryDataService } from '@services';
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';

//angular-in-memory-web-api
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';

//Providers
import { httpInterceptorProviders } from '@interceptors';
import { HttpModule } from '@angular/http';
import {MatInputModule, MatFormFieldModule, MatIconModule, MatSelectModule, MatOptionModule} from '@angular/material';



export function createCompiler(compilerFactory: CompilerFactory) {
  return compilerFactory.createCompiler();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CalendarComponent,
    ReportsComponent,
    CustomerComponent,
    DatatableComponent,
    TasksComponent,
    CustomerdtComponent,
    CustomerDetailsComponent,
    TasksdtComponent,
    TaskdetailsComponent,
    UpdatetaskDialogComponent,
    NewnotetaskDialogComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent
  ],
  imports: [
    CommonModule,
    StorageServiceModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule, 
    MatOptionModule,
    ReactiveFormsModule,
    NgxGaugeModule,
    BrowserAnimationsModule,
    HttpClientModule,
    Ng2GoogleChartsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CalendarModule.forRoot(),
    NgbModule.forRoot(),
    HttpClientInMemoryWebApiModule.forRoot(
      InMemoryDataService, {
        dataEncapsulation: false,
        passThruUnknownUrl: true,
        put204: false // return entity after PUT/update
      }
    ),
    HttpModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


