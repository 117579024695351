import { Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // When you login you send the token that is why you have to check if exist the token in the header
    if (!request.headers.has('Authorization')) {
      // get token from localstorage
      const userData = this.storage.get('userdata');
      if (userData) {
        const token: string = userData.access_token;
        // validate
        if (token) {
          // add token
          request = request.clone({ headers: request.headers.set('Authorization', 'BEARER ' + token) });
        }
      }
    }
    return next.handle(request);
  }
  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService) { }
}
