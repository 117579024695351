import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../services/login/login.service'
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { Router } from "@angular/router";
import { BaseComponent } from '@shared';
import { AuthService } from '@services';
import { ILoginResponse } from '@app/objects';


declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent {

  constructor(private translate: TranslateService,
    private loginservice: LoginService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private router: Router, private authService: AuthService) {
    super();
    this.language = '';
  }

  username: string;
  password: string;
  language: string;
  showPass = false;  

  ngOnInit() {
    super.ngOnInit()
  }

  togglePass(){
    if (this.showPass){
      $("#inptPass").attr('type', 'password');
    } else{
      $("#inptPass").attr('type', 'text');
    }
    this.showPass = !this.showPass;
  }

  selected() {
    this.translate.use(this.language);
  }

  onSubmit() {
    this.login();
    this.authService.setAuthenticated(null);
    // this.router.navigate(['/main']);
  }

  login(): void {
    this.loginservice.login(this.username, this.password).subscribe(
      (resp: ILoginResponse) => {
        this.storage.set('userdata', resp.token);
        this.router.navigate(['/main']);
      },
      err => {
        console.log(err);
      });
  }
}
