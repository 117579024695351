import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Route } from '@angular/router';
import { RouterService } from '@app/services';
import { UIBehavior } from '../uibehavior';
import * as _ from 'lodash';

declare var $: any;

@Component({
  selector: 'app-left-sidebar-menu',
  templateUrl: './left-sidebar-menu.component.html',
  styleUrls: ['./left-sidebar-menu.component.scss']
})
export class LeftSidebarMenuComponent implements OnInit {
  existingRoutes$: Route[];
  
  constructor(private routerService: RouterService) {
    // this.existingRoutes$ = 
  }
  
  ngOnInit() {
    this.routerService.existingRoutes.subscribe(routeList => {
      _.remove(routeList, (route) => {
        if(route.data) {
          return !route.data.showOnMenu;
        }
        return false;
      });
      
      let arr = [];
      
      // arr = routeList[0].data.children;
      
      for (let a = 0; a < routeList.length; a++){
        for (let i = 0; i < routeList[a].data.children.length; i++){
          if (routeList[a].data.children[i].path == "" && routeList[a].data.children[i].data.id){
            for(let j=0; j < routeList[a].data.children.length; j++){
              if ((routeList[a].data.children[j].data.parent != null || routeList[a].data.children[j].data.parent != undefined) && routeList[a].data.children[j].data.parent == routeList[a].data.children[i].data.id){
                if (routeList[a].data.children[i].data == null || routeList[a].data.children[i].data == undefined){
                  routeList[a].data.children[i].data = {};
                }
                if (routeList[a].data.children[i].data.children == null || routeList[a].data.children[i].data.children == undefined){
                  routeList[a].data.children[i].data.children = [];
                }
                routeList[a].data.children[i].data.children.push(routeList[a].data.children[j]);
              }
            }
          }
        }
        
        _.remove(routeList[a].data.children, (i) => {
          return i.data.parent;
        });
      } 
      
      this.existingRoutes$ = routeList;
    });
  }
  
  getRouterLink(routePath: string) {
    return [`/main/${routePath}`];
  }
  
  getChildRouterLink(route, child) {
    return [`/main/${route.path}/${child.path}`];
    
  }
  
  getClicked(index) {
    $(`#menu-item-${index}`).collapse('toggle');
  }

  getInnerClicked(index) {
    $(`#dropdown_dr_lv${index}`).collapse('toggle');
  }
}
