import { Component } from '@angular/core';

@Component({
  selector: 'app-tasksdt',
  templateUrl: './tasksdt.component.html',
  styleUrls: ['./tasksdt.component.scss']
})

export class TasksdtComponent {
  displayedColumns = ['name', 'status', 'registrationdate', 'action'];
  dataSource = ELEMENT_DATA;
  }
  export interface PeriodicElement {
    name: string;
    status: String;
    registrationdate: String;
    action: String;
  }
  
  const ELEMENT_DATA: PeriodicElement[] = [
    {name: 'Contact James', status: 'New', registrationdate: '05/20/2018 10:00:02 PM', action: '10'},
    {name: 'Contact Jacquie', status: 'New', registrationdate: '05/20/2018 10:00:02 PM', action: '200'},
  ];


