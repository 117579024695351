import { Component, OnInit, OnDestroy, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LoaderService, RouterService, ModuleService } from '@services';
import { ModuleData } from '@objects';

// RxJS
import 'rxjs/add/operator/do';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  private subscription: Subscription;
  public show = false;

  constructor(
    private translate: TranslateService, 
    private loaderService: LoaderService,
    private cdRef: ChangeDetectorRef) {
    translate.setDefaultLang('pt-br');
  }

  ngOnInit(): void {
    this.subscription = this.loaderService.loaderState
      .subscribe((state: number) => {
        this.show = state > 0;
      });
  }

  ngAfterViewChecked(): void {
    let show = this.loaderService.apiCallCounter > 0;
    if (show !== this.show) {
      this.show = show;
      this.cdRef.detectChanges();
    }
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  useLanguage(language: string) {
    this.translate.use(language);
  }

  title = 'app';
}
