import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http'; 
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UsersService {

  constructor(private http: HttpClient) { }

  url = environment.apiEndpoint + 'api/users';

  public search (): Observable<any>{
    return this.http.get(this.url);
  }


}
