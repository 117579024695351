import { Component, OnInit } from '@angular/core';
import { UsersService } from '@services';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { BaseComponent } from '@app/shared/base-component';

declare var $: any;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends BaseComponent  implements OnInit  {

  constructor(private usersService: UsersService,
    private router: Router) {
    super();
  }
  users: Observable<any>;

  ngOnInit() {
    $('[data-toggle="tooltip"]').tooltip(); 
    this.search();
    super.ngOnInit();
  }
  showPass=false;
  search(){
    this.users = this.usersService.search();
  }

  editProfile(){
    $("#editUserModal").modal('show');
  }

  toggleChangeLanguage(){
    $("#changeLanguageModal").modal('show');
  }

  toggleMenu() {
    $(".wrapper").removeClass('open-right-sidebar open-setting-panel').toggleClass('slide-nav-toggle');
  }

  toggleRightSidebar() {
    $(".wrapper").toggleClass('open-right-sidebar').removeClass('open-setting-panel');
  }

  toggleMobileNav() {
    $(".wrapper").toggleClass('mobile-nav-open').removeClass('open-right-sidebar');
  }
  
  togglePass(){
    if (this.showPass){
      $("#inptPass").attr('type', 'password');
    } else{
      $("#inptPass").attr('type', 'text');
    }
    this.showPass = !this.showPass;
  }
  openRemoveCompanyModal(){
    $("#removeCompanyModal").modal('show');
  }
  CreateUser(){
    this.router.navigate(['/main/userscreate']);
  }
  EditUser(i){
    this.router.navigate(['/main/user/:'+i]);
  }
}
