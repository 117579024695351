import { Injectable, OnInit } from '@angular/core';
import { UIBehavior } from "./uibehavior";

@Injectable()
export abstract class BaseComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    UIBehavior.Preloader();
  }
}