declare var $: any;
declare function startPreloader(): any;
declare function toggleMenuItens(): any;
declare function registerBodyClickToCloseRightSideBarFilter(): any;
declare function setHeightWidth(): any;

export class UIBehavior {
    public static Preloader() {
        startPreloader();
        registerBodyClickToCloseRightSideBarFilter();
        setHeightWidth();
    }

    public static ToggleMenuItens() {
        toggleMenuItens();
    }
}
