import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-newnotetask-dialog',
  templateUrl: './newnotetask-dialog.component.html',
  styleUrls: ['./newnotetask-dialog.component.scss']
})
export class NewnotetaskDialogComponent implements OnInit {

  form: FormGroup;
  
  
  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<NewnotetaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
  ) {}

  ngOnInit() {

    //this.form = this.formBuilder.group({
    //  name: this.data ? this.data.name : '',
    //  type: this.data ? this.data.type : '',
    //  status: this.data ? this.data.status : '',
    //  assigned: this.data ? this.data.assigned : ''
    //})
  }

  submit(form) {
    this.dialogRef.close(`${form.value.filename}`);
  }
}

