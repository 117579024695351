import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'app/shared/base-component';
import { Router } from '@angular/router';


declare var $: any;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends BaseComponent {
  public newPassword: any;
  public concPassword: any;
  constructor(private router: Router) { 
    super();
  }
showPass=false;
showPass2=false;

  ngOnInit() {
    super.ngOnInit();
  }
onNext(){
  this.router.navigate(['/home']);
}
togglePass(){
  if (this.showPass){
    $("#inptPass").attr('type', 'password');
  } else{
    $("#inptPass").attr('type', 'text');
  }
  
  this.showPass = !this.showPass;
}
togglePass2(){
  if (this.showPass2){
    $("#inptPass").attr('type', 'password');
  } else{
    $("#inptPass").attr('type', 'text');
  }
  
  this.showPass2 = !this.showPass2;
}
}
