import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    
    constructor() { }
    
    displayMessage(warningLevel): void {
        
        let message = "";
        let title = "";
        let color = "";
        let icon = "";
        switch (warningLevel) {
            case 0:
            message = "Sucesso na operação";
            title = "Sucesso";
            color = "#43BB85";
            icon = "success";
            break;
            case 1:
            message = "Erro na operação";
            title = "Erro";
            color = "#ff354d";
            icon = "error";
            break;
            case 2:
            message = "Algo deu errado";
            title = "Aviso";
            color = "#635FAA";
            icon = "warning";
            break;
            default:
            message = "Sucesso na operação";
            title = "Sucesso";
            color = "#43BB85";
            break;
        }
        
        $.toast({
            heading: title,
            text: message,
            position: 'bottom-right',
            loaderBg: color,
            icon: icon,
            hideAfter: 3500, 
            stack: 6
        });
    }
}