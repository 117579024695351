import { Component } from '@angular/core';

@Component({
  selector: 'app-customerdt',
  templateUrl: './customerdt.component.html',
  styleUrls: ['./customerdt.component.scss']
})

export class CustomerdtComponent {
  displayedColumns = ['id','name', 'phone', 'email', 'city','state','country'];
  dataSource = ELEMENT_DATA;
  }
  export interface PeriodicElement {
    id: number;
    name: string;
    phone: String;
    email:String;
    city:String;
    state:String;
    country:String;
  }
  
  const ELEMENT_DATA: PeriodicElement[] = [
    {id: 1, name: 'Tharsus Proux', phone: '+552199777-7321', email: 'tharsus.proux@valid.com', city: 'São Paulo',state: 'São Paulo',country:'Brasil'},
    {id: 2, name: 'Gustavo Lima', phone: '+552199777-7321', email: 'gustavo.lima@valid.com', city: 'São Paulo',state: 'São Paulo',country:'Brasil'},
  ];
  