import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})

export class DatatableComponent {
displayedColumns = ['name', 'modifieddate', 'available', 'quantity'];
dataSource = ELEMENT_DATA;
}
export interface PeriodicElement {
  name: string;
  modifieddate: String;
  available: String;
  quantity: number;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {name: 'Sales List', modifieddate: '9 November 2016 16:16:02', available: 'SMS - E-mail', quantity: 10},
  {name: 'Customers List', modifieddate: '9 November 2016 16:16:02', available: 'SMS', quantity: 200},
];
