import { Component, OnInit } from '@angular/core';
import { RouterService, ModuleService } from '@app/services';
import { ModuleData } from '@app/objects';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  installedModules$: any;
  errorMessage: string;
  errorVisible = false;
  constructor(private routerService: RouterService, private moduleService: ModuleService) { }

  ngOnInit() {
    // Observação: modules.json sempre deve retornar registered como false.
    this.installedModules$ = this.moduleService.loadModules().do(res =>
      res.forEach(x => {
        if (x.registered || res.length === 1)
          this.registerRoute(x);
      })
    );
  }

  enableModule(moduleToEnable: ModuleData) {
    // habilita ou desabilita o módulo
    if (this.isRegistered(moduleToEnable)) {
      this.routerService.unRegisterRoute(moduleToEnable.path);
    } else {
      this.registerRoute(moduleToEnable);
    }
  }

  isRegistered(moduleData: ModuleData): boolean {
    return this.routerService.routeIsRegistered(moduleData.path);
  }

  registerRoute(moduleToEnable: ModuleData) {

    // carregar o arquivo umd e registrar a rota sempre que for bem-sucedido.
    this.moduleService.loadModuleSystemJS(moduleToEnable).then((exports) => {
      this.routerService.createAndRegisterRoute(moduleToEnable, exports);
    }, (err) => this.showError(`${moduleToEnable.moduleName} could not be found, did you copy the umd file to ${moduleToEnable.location}?`, err));
  }

  private showError(message: string, err) {
    this.errorMessage = message;
    this.errorVisible = true;
    console.log(err); // Exibe o erro no console
  }

  closeError() {
    this.errorVisible = false;
  }

}
