import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-change-company',
  templateUrl: './change-company.component.html',
  styleUrls: ['./change-company.component.scss']
})
export class ChangeCompanyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

  toggleChangeLanguage(){
    $("#changeLanguageModal").modal('show');
  }

  toggleMenu() {
    $(".wrapper").removeClass('open-right-sidebar open-setting-panel').toggleClass('slide-nav-toggle');
  }

  toggleRightSidebar() {
    $(".wrapper").toggleClass('open-right-sidebar').removeClass('open-setting-panel');
  }

  toggleMobileNav() {
    $(".wrapper").toggleClass('mobile-nav-open').removeClass('open-right-sidebar');
  }

}
