import { Component, OnInit } from '@angular/core';
import { CompaniesService } from '@services';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  constructor(private companiesService: CompaniesService, private router: Router) { }

  companies: Observable<any>;

  ngOnInit() {
    this.search();
  }

  search() {
    this.companies = this.companiesService.search();
  }

  openRemoveCompanyModal() {
    $("#removeCompanyModal").modal('show');
  }

  navigateToNewCompany() {
    this.router.navigate(['/main/company/0']);
  }

}
