import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isHidden: boolean;
  constructor(private translate: TranslateService, private router: Router) { 
    this.translate.use("pt-br");
    this.isHidden = true;
  }

  ngOnInit() {
    
  }

  toggleUserMenu() {
    this.isHidden = !this.isHidden;
  }

  logout() {
    localStorage.removeItem("user");
    this.router.navigate(['/login']);
  }

  toggleChangeLanguage(){
    $("#changeLanguageModal").modal('show');
  }

  toggleChangeCompany(){
    $("#changeCompanyModal").modal('show');
  }

  toggleChangePassword(){
    $("#changePasswordModal").modal('show');
  }

  toggleMenu() {
    $(".wrapper").removeClass('open-right-sidebar open-setting-panel').toggleClass('slide-nav-toggle');
  }

  toggleRightSidebar() {
    $(".wrapper").toggleClass('open-right-sidebar').removeClass('open-setting-panel');
  }

  toggleMobileNav() {
    $(".wrapper").toggleClass('mobile-nav-open').removeClass('open-right-sidebar');
  }

}
