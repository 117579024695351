import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-right-sidebar-menu',
  templateUrl: './right-sidebar-menu.component.html',
  styleUrls: ['./right-sidebar-menu.component.scss']
})
export class RightSidebarMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
