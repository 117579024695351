import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'app/shared/base-component';
import { Router } from '@angular/router';


declare var $: any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends BaseComponent {
  public email: any;
  public newPassword: any;
  public concPassword: any;
  
  constructor(private router: Router) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }
  onNext() {
    this.router.navigate(['/changepassword']);
  }
}
