import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material';
import {UpdatetaskDialogComponent} from '../updatetask-dialog/updatetask-dialog.component';
import {NewnotetaskDialogComponent} from '../newnotetask-dialog/newnotetask-dialog.component';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-taskdetails',
  templateUrl: './taskdetails.component.html',
  styleUrls: ['./taskdetails.component.scss']
})
export class TaskdetailsComponent {

  constructor(public dialog: MatDialog) { }

  updateTaskRef: MatDialogRef<UpdatetaskDialogComponent>;
  newTaskRef: MatDialogRef<NewnotetaskDialogComponent>;

  updateTask() {
    this.updateTaskRef = this.dialog.open(UpdatetaskDialogComponent,
    {hasBackdrop: false,
      data: {
        name: 'Contact James',
        type: 'Call',
        status: 'New',
        assigned: 'Andre Futuro'
      }}
    );

    this.updateTaskRef
        .afterClosed()
        .pipe(filter(name => name))
        .subscribe(name => console.log(name));
  }
  newNote() {
    this.newTaskRef = this.dialog.open(NewnotetaskDialogComponent,
      {hasBackdrop: false,
        data: {
          name: 'Contact James',
          type: 'Call',
          status: 'New',
          assigned: 'Andre Futuro'
        }}
      );
  }

}
