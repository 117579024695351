import { Injectable } from "@angular/core";
import  *  as _ from 'lodash';


@Injectable()
export class AuthService {
    private static _userGUID: string = null;
    private static _userName: string = null;
    private static _userEmail: string = null;
    private static _userPhone: string = null;

    constructor() { }

    public tryToRestoreSession() {
        const content = {
            'token': localStorage.getItem('lastsessiontoken')
        };
    }
    onError(error: any): void {
        alert(error.message);
    }

    restoreSessionCallBack(result) {
        
    }

    public get isSessionActive(): boolean {
        const user = localStorage.getItem("user");
        return !_.isEmpty(user); 
    }

    public get hasProfile(): boolean {
        const user = localStorage.getItem("user");
        return !_.isEmpty(user);
    }

    public setAuthenticated(userGUID: string): void {
        localStorage.setItem("user", "admin");
    }

    public setProfile(userName: string, userEmail: string, userPhone?: string): void {
        localStorage.setItem("user", "admin");
    }

    public restoreSession(): void {
        
    }

    public saveSession(userGUID: string, userName: string, userEmail: string, userPhone: string): void {
        
    }
}
