import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  public apiCallCounter: number;
  private loaderSubject = new Subject<number>();
  loaderState = this.loaderSubject.asObservable();
  constructor() { 
    this.apiCallCounter = 0;
  }
  incrementApiCallCounter() {
    this.loaderSubject.next( this.apiCallCounter++ );
  }
  decrementApiCallCounter() {
    this.loaderSubject.next(this.apiCallCounter--);
  }
}
