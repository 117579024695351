import { Http } from '@angular/http';
import { ModuleData } from '../objects/module.model';
import { Router, Route } from '@angular/router';
import { Injectable, Compiler } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class RouterService {
    existingRoutes: BehaviorSubject<Route[]>;

    constructor(private router: Router) {
        this.existingRoutes = new BehaviorSubject<Route[]>(this.routes);
    }

    private get routes(): Route[] {
        var routesToReturn = this.router.config;
        return routesToReturn.filter(x => x.path !== "");
    }

    createAndRegisterRoute(moduleToRegister: ModuleData, exports: any) {
        let route: Route = {
            path: moduleToRegister.path,
            data: {
                showOnMenu: true,
                children: exports["ADMIN_ROUTES"],
                name: moduleToRegister.title,
                iconClass: moduleToRegister.iconClass
            },
            loadChildren: () => exports[`${moduleToRegister.moduleName}`]
        };

        this.registerRoute(route);
    }

    routeIsRegistered(path: string) {
        return this.router.config.filter(r => r.path === path).length > 0;
    }

    registerRoute(route: Route) {
        if (this.routeIsRegistered(route.path)) return;

        this.router.config.push(route);

        this.router.config.forEach(root => {
            if (root.loadChildren) {
                if (root.path === 'main' && (root as any)._loadedConfig.routes.length > 0) {
                    let mainRoute: any = root as any;
                    mainRoute._loadedConfig.routes.forEach(childRoute => {
                        if (!childRoute.children) {
                            childRoute.children = [];
                        }
                        if(route.data && route.data.children) {
                            _.remove(route.data.children, (child) => {
                                return child.path === "" && !child.data.id;
                            })
                        }
                        childRoute.children.push(route);
                    });
                }
            }
        });

        this.updateRouteConfig(this.router.config);
    }

    unRegisterRoute(path: string) {
        console.log("Unregister", path);
        this.updateRouteConfig(this.router.config.filter(route => route.path !== path));
    }

    private updateRouteConfig(config) {
        this.router.resetConfig(config);
        this.existingRoutes.next(this.routes);
    }
}