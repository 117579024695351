import { InMemoryDbService } from 'angular-in-memory-web-api';

export class InMemoryDataService implements InMemoryDbService {
  createDb() {
    const users = [
      { id: 11, name: 'Bruno ', lastName: "Buriolla", userName: "bruno.buriolla", password: "C0de1234!", profile: "Admin" },
      { id: 12, name: 'Vitor ', lastName: "Bertolazo", userName: "vitor.bertolazo", password: "C0de1234!",  profile: "User" },
      { id: 13, name: 'Bruno ', lastName: "Leitão", userName: "bruno.leitao", password: "C0de1234!",  profile: "Admin" },
      { id: 14, name: 'Eduardo ', lastName: "Megna",  userName: "eduardo.megna", password: "C0de1234!", profile: "User" },
    ];

    const mailinglists = [
      { id: 11, name: 'Lista Mailing 1', description: "Lista de e-mails do cliente 1", date: new Date() },
      { id: 12, name: 'Lista Mailing 2', description: "Lista de e-mails do cliente 2", date: new Date() },
      { id: 13, name: 'Lista Mailing 3', description: "Lista de e-mails do cliente 3", date: new Date() },
      { id: 14, name: 'Lista Mailing 4', description: "Lista de e-mails do cliente 4", date: new Date() },
      { id: 15, name: 'Lista Mailing 5', description: "Lista de e-mails do cliente 5", date: new Date() },
    ];

    const listManagement = [
      { id: 11, name: 'Bruno Buriolla', email: "bruno.buriolla@codecycle.com", phone: "(11) 99999-9999" },
      { id: 12, name: 'Vitor Bertolazo', email: "vitor.bertolazo@codecycle.com", phone: "(11) 99999-9999" },
      { id: 13, name: 'Bruno Leitão', email: "bruno.leitao@codecycle.com", phone: "(11) 99999-9999" },
      { id: 14, name: 'Eduardo Megna', email: "eduardo.megna@codecycle.com", phone: "(11) 99999-9999" },
    ];

    const companies = [
      { id: 11, name: 'Buriolla', country: "Brasil",},
      { id: 12, name: 'Bertolazo', country: "Canada", },
      { id: 13, name: 'Leitão', country: "Russia", },
      { id: 14, name: 'Megna', country: "Italia", },
    ];

    const profiles = [
      { id: 11, name: 'Administrador', actions: "Ler, Escrever"},
      { id: 12, name: 'Master', actions: "Ler, Escrever, Apagar" },
      { id: 13, name: 'User', actions: "Ler" },
    ];

    const clients = [
      { id: 11, name: 'Bruno Buriolla', email: "bruno.buriolla@codecycle.com", phone: "(11) 99999-9999", strongKey: "32244000" },
      { id: 12, name: 'Vitor Bertolazo', email: "vitor.bertolazo@codecycle.com", phone: "(11) 99999-9999", strongKey: "32244000" },
      { id: 13, name: 'Bruno Leitão', email: "bruno.leitao@codecycle.com", phone: "(11) 99999-9999", strongKey: "32244000" },
      { id: 14, name: 'Eduardo Megna', email: "eduardo.megna@codecycle.com", phone: "(11) 99999-9999", strongKey: "32244000" },
    ];

    const transactions = [
      { messageid: 110, to: 'Brun@codecycle.com.br', processedon: new Date(), status: 0, clickedon: new Date(), url: 'https://valid.com.br', openedon: new Date(), deliveredon: new Date(), failedon: new Date() },
      { messageid: 110, to: 'Vit@codecycle.com.br', processedon: new Date(), status: 1, clickedon: new Date(), url: 'https://valid.com.br', openedon: new Date(), deliveredon: new Date(), failedon: new Date() },
      { messageid: 110, to: 'José@asd.com', processedon: new Date(), status: 0, clickedon: new Date(), url: 'https://valid.com.br', openedon: new Date(), deliveredon: new Date(), failedon: new Date() },
      { messageid: 110, to: 'João@asd.com', processedon: new Date(), status: 1, clickedon: new Date(), url: 'https://valid.com.br', openedon: new Date(), deliveredon: new Date(), failedon: new Date() },
      { messageid: 110, to: 'Dan@asd.com', processedon: new Date(), status: 0, clickedon: "", url: 'https://valid.com.br', openedon: new Date(), deliveredon: new Date(), failedon: new Date() },
      { messageid: 110, to: 'Ric@asd.com', processedon: new Date(), status: 1, clickedon: "", url: 'https://valid.com.br', openedon: new Date(), deliveredon: new Date(), failedon: new Date() },
      { messageid: 110, to: 'Edu@asd.com', processedon: new Date(), status: 0, clickedon: "", url: 'https://valid.com.br', openedon: new Date(), deliveredon: new Date(), failedon: new Date() },
      { messageid: 110, to: 'Mat@asd.com', processedon: new Date(), status: 1, clickedon: new Date(), url: 'https://valid.com.br', openedon: new Date(), deliveredon: new Date(), failedon: new Date() },
      { messageid: 110, to: 'Erik@asd.com', processedon: new Date(), status: 0, clickedon: new Date(), url: 'https://valid.com.br', openedon: new Date(), deliveredon: new Date(), failedon: new Date() },
      { messageid: 110, to: 'Rod@asd.com', processedon: new Date(), status: 1, clickedon: new Date(), url: 'https://valid.com.br', openedon: new Date(), deliveredon: new Date(), failedon: new Date() },
      { messageid: 110, to: 'Di@asd.com', processedon: new Date(), status: 0, clickedon: new Date(), url: 'https://valid.com.br', openedon: new Date(), deliveredon: new Date(), failedon: new Date() },
    ];

    const cycleReports = [
      { cycle: "ABC", processed: 10, failures: 8, sent: 2, opened: 5, clicks: 7, clickspercentage: "70%" },
      { cycle: "ABC", processed: 10, failures: 8, sent: 2, opened: 5, clicks: 7, clickspercentage: "70%" },
      { cycle: "ABC", processed: 10, failures: 8, sent: 2, opened: 5, clicks: 7, clickspercentage: "70%" },
      { cycle: "ABC", processed: 10, failures: 8, sent: 2, opened: 5, clicks: 7, clickspercentage: "70%" },
      { cycle: "ABC", processed: 10, failures: 8, sent: 2, opened: 5, clicks: 7, clickspercentage: "70%" },
      { cycle: "ABC", processed: 10, failures: 8, sent: 2, opened: 5, clicks: 7, clickspercentage: "70%" },
      { cycle: "ABC", processed: 10, failures: 8, sent: 2, opened: 5, clicks: 7, clickspercentage: "70%" },
      { cycle: "ABC", processed: 10, failures: 8, sent: 2, opened: 5, clicks: 7, clickspercentage: "70%" },
      { cycle: "ABC", processed: 10, failures: 8, sent: 2, opened: 5, clicks: 7, clickspercentage: "70%" },
      { cycle: "ABC", processed: 10, failures: 8, sent: 2, opened: 5, clicks: 7, clickspercentage: "70%" },
      { cycle: "ABC", processed: 10, failures: 8, sent: 2, opened: 5, clicks: 7, clickspercentage: "70%" },
      { cycle: "ABC", processed: 10, failures: 8, sent: 2, opened: 5, clicks: 7, clickspercentage: "70%" },
    ];
    return {users, mailinglists, listManagement, transactions, clients, cycleReports, companies, profiles};
  }
}