import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent, ForgotPasswordComponent, ChangePasswordComponent } from '@modules';
import { RouteGuardService, AuthService } from '@services';


const routes: Routes = [
  {path:'', redirectTo: 'main', pathMatch: 'full', data: { showOnMenu: false }},
  { path: 'main', loadChildren: "app/modules/main/main.module#MainModule", canActivate: [RouteGuardService], data: { showOnMenu: false } },
  { path: 'login', component: LoginComponent, data: { showOnMenu: false }},
  { path: 'forgotpassword', component: ForgotPasswordComponent, data: { showOnMenu: false }},
  { path: 'changepassword', component: ChangePasswordComponent, data: { showOnMenu: false }}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthService, RouteGuardService]
})
export class AppRoutingModule { }
